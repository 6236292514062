var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',{staticStyle:{"margin-top":"10vh"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-container',[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var passes = ref.passes;
var validate = ref.validate;
return [_c('v-card',{staticClass:"elevation-1 pa-3"},[_c('v-row',[_c('v-col',{staticClass:"login-background hidden-md-and-down",attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"5"}}),_c('v-col',{staticStyle:{"padding":"3vw 5vw 4vw 5vw"},attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"7"}},[_c('v-card-text',[(_vm.getClientBrandLogo())?_c('div',{staticClass:"layout column align-center"},[_c('img',{staticClass:"logoStyle",attrs:{"src":_vm.getClientBrandImageUrl(),"alt":"logo"}})]):_c('div',{staticClass:"layout column align-center"},[_c('img',{staticClass:"eInsightsLogoStyle",attrs:{"alt":"Vue Material Admin","src":require("../assets/eInsights.png")}})]),_c('v-form',{staticClass:"mt-2"},[_c('div',[_vm._v(_vm._s(_vm.$t('msg.username_label')))]),_c('ValidationProvider',{attrs:{"rules":"required","name":"username"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var failedRules = ref.failedRules;
return [_c('v-text-field',{attrs:{"append-icon":"person","required":"","name":"username","placeholder":"Username","type":"text","outlined":"","autocomplete":"new-password","error-messages":_vm.$translatedErrorMessages(failedRules,'username'),"success":valid,"id":"username"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return passes(_vm.login)}},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}],null,true)}),_c('div',[_vm._v(_vm._s(_vm.$t('msg.password_label')))]),_c('ValidationProvider',{attrs:{"rules":"required","name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var failedRules = ref.failedRules;
return [_c('v-text-field',{attrs:{"append-icon":"lock","placeholder":"********","name":"password","type":"password","outlined":"","autocomplete":"new-password","error-messages":_vm.$translatedErrorMessages(failedRules,'password'),"success":valid,"id":"password"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return passes(_vm.login)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('v-spacer')],1)],1),_c('v-card-actions',{staticClass:"justify-center pb-5"},[_c('v-btn',{staticClass:"login-button",attrs:{"loading":_vm.loading},on:{"click":function($event){return passes(_vm.login)}}},[_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"pr-8",attrs:{"indeterminate":"","size":20,"width":3,"color":"white"}}),_vm._v(" "+_vm._s(_vm.$t('msg.login_button'))+" ")],1)],1)],1)],1)],1)]}}])})],1)],1)],1),_c('v-row',[_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"color":"error","right":"","top":""},model:{value:(_vm.invalidUserCredentials),callback:function ($$v) {_vm.invalidUserCredentials=$$v},expression:"invalidUserCredentials"}},[_vm._v(" "+_vm._s(_vm.$t('msg.error'))+" ")]),_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"color":"error","right":"","top":""},model:{value:(_vm.userValidityExpired),callback:function ($$v) {_vm.userValidityExpired=$$v},expression:"userValidityExpired"}},[_vm._v(" "+_vm._s(_vm.$t('msg.expired_validity'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }